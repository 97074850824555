<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="header-wrap">
                    <div class="user_info-wrap fx-middle" style="margin-top: 0;">
                        <van-cell v-if="hasGetMemberInfo" center style="background-color: transparent">
                            <template #icon>
                                <van-image :src="memberInfo.avatar ? memberInfo.avatar : require('@/assets/images/user/user_avatar_default.png')" width="60" height="60" round fit="cover" style="margin-right: 10px;"></van-image>
                            </template>
                            <template #title>
                                <div style="color:#fff">{{memberInfo.nickName}}</div>
                            </template>
                            <template #label>
                                <div style="color: #fff">{{memberInfo.mobile | hideMobile}}</div>
                            </template>
                            <div style="color: #fff" @click="beforeLogout">退出登录</div>
                        </van-cell>
                        <van-cell v-else center style="background-color: transparent" @click="$goPage('login')">
                            <template #icon>
                                <van-image :src="require('@/assets/images/user/user_avatar_offline.png')" width="60" height="60" round fit="cover" style="margin-right: 10px;"></van-image>
                            </template>
                            <template #title>
                                <div style="color: #fff" @click="$goPage('login')">立即登录</div>
                            </template>
                        </van-cell>
                    </div>
                </div>
                <div class="user_func-wrap">
                    <div class="user_order">
                        <van-cell>
                            <template #title>
                                <div class="user_func-title">我的订单</div>
                            </template>
                        </van-cell>
                        <div class="user_order-tab fx-row fx-around">
                            <div class="fx-center" @click="toUserOrder(0)">
                                <van-icon :name="require('@/assets/images/user/movie-order.png')" size="25"></van-icon>
                                <span>电影票</span>
                            </div>
                            <div class="fx-center" @click="toUserOrder(2)">
                                <van-icon :name="require('@/assets/images/user/bmh.png')" size="25"></van-icon>
                                <span>影院小食</span>
                            </div>
                            <div class="fx-center" @click="toUserOrder(1)">
                                <van-icon :name="require('@/assets/images/user/recharge-order.png')" size="25"></van-icon>
                                <span>音乐视频</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="user_func-wrap">
                    <div class="user_order">
                        <div class="user-service">
                            <div @click="toCoupon">
                                <span class="service-title">优惠券</span>
                                <span class="service-desc">购票享优惠</span>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="user_func-wrap">
                    <div class="user_order">
                        <van-cell-group>
                            <van-cell title="优惠券" is-link @click="toCoupon">
                                <div class="fs-7">购票享优惠</div>
                            </van-cell>
                            <van-cell title="我的余额" is-link @click="toBanalce">
                                <div class="fs-9" v-if="isLogin">￥{{memberInfo.memberAccountBalance}}</div>
                            </van-cell>
                        </van-cell-group>
                    </div>
                </div>
                <div class="user_func-wrap">
                    <div class="user_order">
                        <van-cell-group>
                            <!-- <van-cell title="我要分享" is-link @click="toShare" /> -->
                            <!-- <van-cell title="意见反馈" is-link @click="toFeedback" /> -->
                            <van-cell title="联系客服" is-link :url="'tel:' + baseData.serviceTel" />
                            <van-cell title="关于我们">
                                <div class="fs-7">版本号 {{version}}</div>
                            </van-cell>
                            <van-cell title="清除缓存" is-link @click="clearCache" />
                            <!-- <van-cell title="关注公众号" is-link @click="toGzh">
                                <div class="fs-7">及时获取优惠信息</div>
                            </van-cell> -->
                        </van-cell-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap">
            <tab-bar></tab-bar>
        </div>
        <div class="utils-wrap">
            <!-- <div class="share-wrap">
                <div class="share_content">
                    <div class="img-link" ref="imgLink">
                        <div class="share_user-avatar">
                            <van-image :src="memberInfo.avatar ? memberInfo.avatar : require('@/assets/images/user/user_avatar_default.png')" width="80" height="80" fit="cover"></van-image>
                        </div>
                        <div class="share_user-nickname">
                            <div>{{memberInfo.nickName}}</div>
                        </div>
                        <vue-qr :logoSrc="logoSrc" :text="shareLink"></vue-qr>
                    </div>
                </div>
            </div>
            <van-overlay :show="showImgLink" @click="showImgLink = false" z-index="1009">
                <div class="imgLink-wrap">
                    <img :src="cvImg" alt="" class="cv-img">
                    <van-button style="margin-top: 30px;padding: 0 50px;" round>长按上方图片保存到相册</van-button>
                </div>
            </van-overlay> -->
        </div>
    </div>
</template>

<script>
import cookies from 'vue-cookies';
import config from '@/config'
import TabBar from "@/components/tab-bar";
import { mapActions, mapGetters } from 'vuex';
import { subtr } from '@/libs/number-utils'
import vueQr from 'vue-qr'
import html2canvas from 'html2canvas'
import { isEmpty } from 'lodash'
import { version} from '@package'
export default {
    data() {
        return {
            couponTotal: 0,
            exchangeCardTotal: 0,
            vipTotal: 0,
            show: false,
            logoSrc: require("@/assets/logo.jpg"),
            showImgLink: false,
            cvImg: "",
            imgUrl: '',
            version,
        }
    },
    computed: {
        ...mapGetters([
            'hasGetMemberInfo',
            'authCode',
            'appKey',
            'baseData'
        ]),
        memberInfo() {
            return this.$store.state.user.memberInfo;
        },
        shareLink() {
            return `${location.protocol}//${location.host}${this.$router.options.base || ''}/index?inviter=${this.memberInfo.id || ''}`
        },
        isLogin() {
            return !isEmpty(this.memberInfo);
        }
    },
    methods: {
        subtr,
        ...mapActions([
            'logout'
        ]),
        getMemberCouponList(type) {
            return new Promise((resolve) => {
                let params = {
                    type,
                }
                this.$service.getMemberCouponList(params).then((res) => {
                    resolve(res.pagination.item_total);
                })
            });
        },
        getVipList() {
            let params = {
                type: 'VIP',
            }
            this.$service.getMemberVipList(params).then((res) => {
                let couponList = res.couponlist;
                if (couponList && couponList.length > 0) {
                    this.vipTotal = couponList[0].balance;
                }
            });
        },
        toCoupon() {
            if (this.validIsLogin()) {
                this.$goPage('user-coupon')
            }
        },
        toVip() {
            if (this.validIsLogin()) {
                this.$goPage('user-vip');
            }
        },
        toUserOrder(index) {
            if (this.validIsLogin()) {
                let name = index == 0 ? 'movie-order' : index == 1 ? 'store-order' : 'food-order'
                this.$goPage(name);
            }
        },
        toFeedback() {
            if (this.validIsLogin()) {
                this.$goPage('feedback');
            }
        },
        toGzh() {
            this.$goPage('gzh');
        },
        toRecharge() {
            this.$goPage('recharge')
        },
        toBanalce() {
            if (this.validIsLogin()) {
                this.$goPage('user-balance')
            }
        },
        beforeLogout() {
            this.$dialog.confirm({
                title: '系统提示',
                message: '确认退出登录吗?'
            }).then(() => {
                this.logout();
            });
        },
        validIsLogin(toast = true) {
            if (!this.authCode) {
                if (toast) {
                    this.$toast('您还未登录');
                }
                return false;
            }
            return true;
        },
        toShare() {
            if (this.validIsLogin()) {
                this.$goPage('user-share')
            }
        },
        //打开分享 depercated
        share() {
            this.setCanvas();
            // this.show = true;
        },
        //分享链接生成图片 depercated
        setCanvas() {
            const canvas = document.createElement('canvas')
            // 获取要生成图片的 DOM 元素
            let canvasDom = this.$refs.imgLink
            // 获取指定的宽高
            const width = parseInt(window.getComputedStyle(canvasDom).width)
            const height = parseInt(window.getComputedStyle(canvasDom).height)
            // 宽高扩大 2 倍 处理图片模糊
            canvas.width = width * 1.5
            canvas.height = height * 1.5
            // canvas.style.width = width / 2 + 'px'
            // canvas.style.height = height /2 + 'px'
            console.log(width, "   ", height)
            const context = canvas.getContext('2d')
            // context.scale(0.5, 0.5)
            const options = {
                backgroundColor: null,
                canvas: canvas,
                useCORS: true,
                scale: 1.5
            }
            html2canvas(canvasDom, options).then(canvas => {
                // 生成图片地址
                this.imgUrl = canvas.toDataURL('image/png')
                this.cvImg = this.imgUrl
                this.showImgLink = true
            })
        },
        clearCache() {
            let caches = cookies.keys().filter(item => item.indexOf('_PXD_') != -1);
            caches.forEach((item) => {
                if (item.indexOf(config.const.KEY_AUTH_CODE) < 0) {
                    cookies.remove(item);
                }
            });
            this.$toast('清除成功')
            window.location.reload();
        },
        initPage() {
        }
    },
    mounted() {
        this.initPage();
    },
    components: {
        TabBar,
        vueQr
    }
}
</script>

<style lang="scss" scoped>

/deep/ .user_func-title {
	font-size: 15px;
	font-weight: 900;
}
.header-wrap {
	position: relative;
	z-index: 0;
	height: 100px;
	// background: linear-gradient($primary-color 130px, $bg-light-color 130px)
	background: linear-gradient(#f5b225, #f8eed9);
	.user_info-wrap {
		color: #fff;
		margin: 12px 0;
		.user_info {
			padding: 10px 18px;
			.user_name {
				padding: 0 10px;
				font-size: 16px;
			}
		}
	}
}

.body-wrap {
	position: relative;
	.user_func-wrap {
		margin-bottom: 20px;
		margin-top: 5px;
		padding: 0 8px;
		.user_func-title {
			margin-bottom: 8px;
		}
		.user_coupon-list {
			.user_coupon-item {
				box-shadow: 0px 2px 3px #bb96c1;
				background-color: #fff;
				border-radius: 6px;
				width: 48%;
				padding: 15px 0;
				> * {
					padding: 0 8px;
				}
				.user_coupon-desc {
					> div {
						line-height: 1.8;
					}
				}
			}
		}
		.user_more {
			background: url("../../assets/images/user/user_more.png") no-repeat;
			padding-left: 28px;
			color: #fff;
			background-size: 100% 100%;
			height: 100px;
		}
	}

	.user_order {
		border-radius: 6px;
		background-color: #fff;
		overflow: hidden;
		box-shadow: 0px 2px 3px #f8eed9;
		.user_order-tab {
			padding: 15px 0;
			> div {
				> span {
					margin-top: 4px;
				}
			}
		}
		.user-service {
			display: flex;
			flex-direction: row;
			> div {
				padding: 15px 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.service-title {
					font-size: 1.25em;
					line-height: 1.8;
				}
				.service-desc {
					color: $tips-color;
				}
			}
		}
	}
}

.share-wrap {
	position: fixed;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.share_content {
		background-color: #fff;
		padding: 0px 30px;
		padding-bottom: 0;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		> .img-link {
			background-color: #fff;
			display: flex;
			flex-direction: column;
			align-items: center;
			> * {
				margin-top: 10px;
				&:last-child {
					margin-bottom: 20px;
				}
			}
		}
		justify-content: center;
	}
}

.imgLink-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}
</style>
